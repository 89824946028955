import React,{useState,useMemo,useEffect} from 'react'

import OneSvg from './assert/Group-1.svg'
import TwoSvg from './assert/Group-2.svg'
import ThreeSvg from './assert/Group-5.svg'
import FourSvg from './assert/Group-3.svg'
import FiveSvg from './assert/Group-4.svg'


import OnePng from './assert/1-Record.png'
import TwoPng from './assert/2-Market.png'
import ThreePng from './assert/3-Check.png'
import FourPng from './assert/4-Acievements.png'
import FivePng from './assert/5-Profile.png'

import './index.css'

const config = {
    one: [
        OneSvg,
        OnePng,
        'History',
        `You can check all your drinking records here, 
        so as to have a deeper understanding of your drinking habit`
    ],
    two:[
        TwoSvg,
        TwoPng,
        'NFT Market',
        `The NFTs of Drink Up are anthropomorphic beverage avatars, 
        created based on the characteristics of eighteen types of beverages. 
        Different beverage types have distinct forms and traits. 
        They are also the admission ticket for you to play our game and earn tokens`
    ],
    three:[
        ThreeSvg,
        ThreePng,
        'Add a (drinking) record',
        `In Drink Up app, you can choose among various options 
        including the type of beverages and the type of beverage 
        containers so as to record your daily drinking activities. 
        With a NFT, you will receive more rewards. 
        You can also check your daily water intake data and rewards 
        through the visualized graphs in the app.`
    ],
    four:[
        FourSvg,
        FourPng,
        'Achievements',
        `After fulfilling daily drinking task, you can see corresponding badges on achievement page. 
        The more you record, the more badges you will receive. 
        In the future, you can unlock the functions of badges, 
        raise the attributes of beverage containers, 
        boost the efficiency of earning tokens. 
        The more badges you win, the more rewards you will receive.`
    ],
    five:[
        FiveSvg,
        FivePng,
        'My Drinking',
        `You can edit your profile information, 
        check your NFTs, add records and change remind methods in My Drinking Page.`
    ]
}

const states = ['one','two','three','four','five']

const HowToPlayBlock = ()=>{
    const [cur,setCur] = useState('one')

    const [icon,png,title,text] = config[cur] 

    const [fontSize,lineHeight ]= useMemo(()=>{
        if(cur === 'one' || cur === 'five'){
            return ['34px','48px']
        }else{
            return ['24px','32px']
        }
    },[cur])

    useEffect(()=>{
        const timer = window.setInterval(()=>{
            setCur(cur=>{
                const index = states.findIndex(item=>item===cur)
                const nextIndex = index === 4 ? 0 : index + 1

                return states[nextIndex]
            })
        },5000)

        return ()=>{
            clearInterval(timer)
        }
    },[])
    
    return (
        <div className={'howtoblock'}>
            <div style={{margin:'72px 72px 48px 72px',width:'640px'}}>
                <img src={icon} width={90} height={90}/>
                <div className={'title'}>
                    {title}
                </div>
                <div className={'text'} style={{fontSize,lineHeight}}>
                    {text}
                </div>
                <div>
                    {states.map(item=>{
                        const [svgSrc] = config[item]
                        const isFocus = cur === item
                        return (
                            <span 
                                key={item} 
                                onClick={()=>setCur(item)}
                                style={{display:'inline-block',cursor:'pointer'}}
                            >
                                <img 
                                    src={svgSrc} 
                                    width={35} 
                                    style={{marginRight:'35px',opacity: isFocus ? 1 : 0.5}}
                                    height={35}
                                />
                                {isFocus && <span style={{
                                    background: '#00FFF0',
                                    marginLeft:'14px',
                                    width:'7px',
                                    height:'3px',
                                    display:'block'
                                    }}></span>}
                            </span>
                        )
                    })}
                </div>
            </div>
            <img src={png} width={375} height={588} style={{marginTop:'65px'}}/>
        </div>
    )
}

export default HowToPlayBlock