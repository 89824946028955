import React from 'react'
import {Modal,Form,Input,message} from 'antd'

import startSrc from '../../../assets/about_us/get-start.png'
import modalTitle from '../../../assets/about_us/model-title.png'
import './style.css'

const Item = Form.Item

const Prefix = ({text}) => <span><span style={{color:'red'}}>*</span>{text}</span>

const SubmitModal = ({onCancel})=>{

    const [form] = Form.useForm()

    const onClick = ()=>{
        form.validateFields().then(values=>{
            window.fetch('/pub/business',{
                method: 'POST',
                mode: 'cors',
                headers: {
                  'content-type': 'application/json;charset=UTF-8',
                },
                credentials: 'include',
                body: JSON.stringify(values),
                redirect: 'manual',
            }).then(res=>{
                console.log(res)
                message.success('提交成功！')
                // onCancel()
            })
        })       
    }


    return (
        <Modal open onCancel={onCancel} footer={null} width={800} closable={false} className={'customModal'}>
            <div style={{textAlign:'center',marginBottom:'32px'}}>
                <img src={modalTitle} alt={'title'} width={460}/>
                <div className={'title'}>Set and submit the profile of your business here</div>
            </div>
            <Form form={form}>
                <Item name={'name'}  rules={[{ required: true, message: 'Please input your Username!' }]}>
                    <Input prefix={<Prefix text={'Name'}/>} autoComplete={'off'}/>
                </Item>
                <Item name={'phone'} rules={[
                    { required: true, message: 'Please input your Username!' }
                    ]}>
                    <Input prefix={<Prefix text={'Phone number'}/>} autoComplete={'off'}/>
                </Item>
                <Item name={'email'} 
                    rules={[{
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    { required: true, message: 'Please input your Username!' }]}>
                    <Input prefix={<Prefix text={'E-mail'}/>} autoComplete={'off'}/>
                </Item>
                <Item name={'company'} rules={[{ required: true, message: 'Please input your Username!' }]}>
                    <Input prefix={<Prefix text={'Company'}/>} autoComplete={'off'}/>
                </Item>
                <Item name={'detail'}>
                    <Input.TextArea rows={4} placeholder={'if you want to tell us more details ......'} autoComplete={'off'}/>
                </Item>
            </Form>
            <div style={{textAlign:'center'}}>
                <img src={startSrc} alt={'start btn'} 
                    width={166} 
                    height={48} 
                    onClick={onClick}
                    style={{cursor:'pointer',marginTop:'16px'}}    
                />
            </div>
        </Modal>
    )
}

export default SubmitModal