import React,{useState} from 'react'

import someCase from '../../assets/about_us/Some cases.png'
import well from '../../assets/about_us/wall.png'
import NFTBlcok from './components/NFTBlock'
import howToTitle from '../../assets/about_us/How-to-get-title.png'
import step from '../../assets/about_us/step4.png'
import commitBtn from '../../assets/about_us/commit-btn.png'
import SubmitModal from './components/SubmitModal'

import './index.css'

const AboutUs = ()=>{
   const [modalOpen,setModalOpen] = useState(false)
    return (
        <div style={{width:'1280px',margin:'0 auto'}}>
            <div className={'aboutUsDes'} style={{marginTop:83,marginBottom:110}}>
                <NFTBlcok />
            </div>
            <div style={{textAlign:'center'}}>
                <img src={someCase} alt={'some case'} width={498} height={90}/>
                <img src={well} alt={'case well'} width={1280} height={657}/>
            </div>
            <div style={{textAlign:'center',marginTop:'168px'}}>
                <img src = {howToTitle} width={1046} height={168} alt={'title'}/>
                <img src = {step} width={1280} height={513} alt={'step'}/>
            </div>
            <div style={{textAlign:'center',marginTop:'168px'}}>
                <span style={{cursor:'pointer'}} onClick={()=>setModalOpen(true)}>
                    <img src={commitBtn} width={273} height={68}/>
                </span>
            </div>
            {
                modalOpen && <SubmitModal onCancel={()=>setModalOpen(false)}/>
            }
        </div>
    )
}

export default AboutUs