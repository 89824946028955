import React, {useState} from 'react'

import './App.css';
import logo from './assets/logo.png'
import Overall from './pages/overall'
import HowToPlay from './pages/how_to_play'
import AboutUs from './pages/about_us'
import bottomImg from './assets/mask_group.png'
import 'antd/dist/antd.css'

function App() {

  const [focus,setFocus] = useState('OVERVIEW')

  return (
    <div className="App">
      <div style={{margin:'0 auto'}}>
        <div className="App-header" style={{width:'1280px',margin:'0 auto'}}>
            <img src={logo} alt={'drink-up-logo'} width={369} height={83}/>
            <div className='APP-menu'>
              {['OVERVIEW','HOW TO PLAY','ABOUT US','WHITE PAPER'].map(title=>(
                <div>
                  <span 
                    key={title} 
                    className={focus === title ? 'menu-item menu-item-focus' : 'menu-item'} 
                    onClick={()=>setFocus(title)}
                  >
                    {title}
                  </span>
                  {focus === title && <span className={'text-decoration-span'}/>}
                </div>
                ))
              }
            </div>
          </div>
          {
            focus === 'OVERVIEW' && <Overall setFocus={setFocus}/>
          }
          {
            focus === 'HOW TO PLAY' && <HowToPlay />
          }
          {
            focus==='ABOUT US' && <AboutUs />
          }
      </div>
      <img src={bottomImg} width={'100%'} style={{marginTop:'95px'}}/>
    </div>
  );
}

export default App;
