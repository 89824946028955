import React from 'react'

const NFTDetail = ({iconSrc,titleSrc,desc})=>{
    return (
        <div style={{display:'flex'}}>
            <div style={{marginTop:'98px'}}>
               <img alt={'nft icon'} src={iconSrc} width={188} height={188}/>
            </div>
            <div style={{marginLeft:'60px'}}>
                <img src= {titleSrc} alt={'nft title'} height={57}/>
                <div style={{paddingLeft:'20px'}} className={'desc'}>
                    {desc}
                </div>
            </div>
        </div>
    )
}

export default NFTDetail