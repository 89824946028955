import React from 'react'

import profile from '../../assets/how_to_play/profile.png'
import prossessNFT from '../../assets/how_to_play/prossessNFT.png'
import detailNft from '../../assets/how_to_play/detail-nfts.png'
import chooseNft from '../../assets/how_to_play/choose-nft.png' 
import socialFi from '../../assets/how_to_play/social-fi.png'
import howToGet from '../../assets/how_to_play/How to get DrinkUp.png'
import HowToPlayBlock from './components/HowToPlay'

import './index.css'


const HowToPlay = ()=>{
    return (
        <div style={{width:'1280px',margin:'0 auto'}}>
            <HowToPlayBlock />
            <img src={profile} alt={'set profile'} width={1280} height={1038} style={{marginTop:120}}/>
            <img src={prossessNFT} alt={'possess nft'} width={1278} height={558} style={{marginTop:135}}/>
            <img src={detailNft} alt={'detailNft'} width={1278} height={1140} style={{marginTop:100}}/>
            <div className={'stepText'}>
              Later on, we will introduce more ways to play with NFTs, such as unlocking more advanced attributes
            </div>
            <img src={chooseNft} alt={'shoose nft'} width={1280} height={780} style={{marginTop:55}}/>
            <img src={socialFi} alt={'social-fi'} width={1280} height={1167} style={{marginTop:210}}/>
            <div style={{margin:'0 auto',width:'max-content',marginTop:'98px'}}>
                <img src={howToGet} alt={'how to get DrinkUp'} width={707} height={95}/>
                <div style={{display:'flex',margin:'75px 56px',justifyContent:'space-between'}} >
                    <div className={'downApp btn'}>
                        I use iPhone
                    </div>
                    <div className={'btn contacUs'}>
                        I use Android
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToPlay