import React,{useRef} from 'react'

import roadmap from '../../assets/overall/road-map.png'
import web3 from '../../assets/overall/web3.png'
import logoImg from '../../assets/overall/ball.png'
import arrowPng from '../../assets/overall/arrow.png'
import cardPng from '../../assets/overall/web3.0-bridge.png'
import titleRoad from '../../assets/overall/title:Roadmap.png'
import nftDesign from '../../assets/overall/icon:NFT DESIGN.png'
import nftDesignTitle from '../../assets/overall/title:nft Design.png'
import nftPromotion from '../../assets/overall/icon:NFT distribution:promotion.png'
import nftPromotionTitle from '../../assets/overall/title:nft promotion.png'
import nftSpread from '../../assets/overall/icon:The embodiment of Gamefi model.png'
import nftSpreadTitle from '../../assets/overall/title:The embodiment of Gamefi model.png'
import brands from '../../assets/overall/title:2.png'
import './index.css'


const Overall = ({setFocus})=>{
    const roadmapRef = useRef()
    return (
        <>
        <div style={{width:'1280px',margin:'0 auto'}}>
            <div style={{marginTop:'92px',display:'flex',alignItems:'center'}}>
                <div>
                   <img src = {web3} width={'802px'} height={'328px'}/>
                   {/* <div className={'desText'} style={{width:'802px'}}>
                        It helps users create a healthier, more convenient and more dynamic 
                        lifestyle. at the same time, make users and brands more sticky.
                   </div> */}
                   <div style={{display:'flex',marginTop:'62px',marginLeft:'20px'}}>
                        <div className={'btn downApp'}>
                            DOWNLOAD APP
                        </div>
                        <div className={'btn contacUs'} style={{marginLeft:'40px'}}>
                            CONTACT US
                        </div>
                       
                   </div>
                  
                </div>
                <div>
                    <img src={logoImg} width={'530px'} height={'457px'}/>
                </div>
            </div>
            <div style={{marginTop:'166px',marginLeft:'20px'}}>
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:'40px'}}>
                    <div>
                        <div className={'playTitle'}>
                            A bridge to Web3.0 for Web2.0 Users 
                        </div>
                        <div className={'desText'} style={{marginTop:'26px'}}>
                           Low-barrier, Super-easy, highly effective
                        </div>
                    </div>
                    <div 
                        className={'howToPlay btn'} 
                        onClick={()=>setFocus('HOW TO PLAY')}
                        style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'35px'}}
                    >
                        HOW TO PLAY <img width={12} height={20} src={arrowPng} style={{marginLeft:'8px'}}/>
                    </div>
                </div>
                <img src={cardPng} width={'1280px'} height={'594px'} alt={'how to play'}/>
            </div>
            <div style={{textAlign:'center'}}>
                <img src={brands} width={'880px'}/>
                <div className={'desText'} style={{marginTop:'42px',marginBottom:'66px'}} >
                  innovative, vertical, efficient
                </div>
                <div 
                    className={'howToPlay btn'} 
                    onClick={()=>setFocus('ABOUT US')}
                    style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'0 auto'}}
                >
                    ABOUT US <img width={12} height={20} src={arrowPng} style={{marginLeft:'6px'}}/>
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'space-around',marginTop:'92px',width:'100%'}}>
                <div className={'nftBlock'}>
                    <img src={nftDesign} alt={'nft design'} width={188} height={188} style={{marginBottom:'48px'}}/>
                    {/* <img src={nftDesignTitle} alt={'dft design title'} width={273} height={57}/> */}
                    <span className={'secondTitle'} style={{lineHeight:'92px'}}>
                      NFT DESIGN
                    </span>
                    <div className={'nftDes'}>
                    Drink Up's professional visual designers will design 
                    the very NFTs that embody corporate culture and brand alike.
                    </div>
                </div>
                <div className={'nftBlock'}>
                    <img src={nftPromotion} alt={'nft design'} width={188} height={188} style={{marginBottom:'48px'}}/>
                    {/* <img src={nftPromotionTitle} alt={'dft design title'} width={273} height={57}/> */}
                    <span className={'secondTitle'}>THE EMBODIMENT OF GAMEFI MODEL</span>
                    <div className={'nftDes'}>
                    Work with Drink Up's product managers and engineers 
                    to design the game values of NFT within Drink Up, 
                    making your NFTs not merely good-looking but fun to play with as well.
                    </div>
                </div>
                <div className={'nftBlock'}>
                    <img src={nftSpread} alt={'nft design'} width={188} height={188} style={{marginBottom:'48px'}}/>
                    {/* <img src={nftSpreadTitle} alt={'dft design title'} width={273} height={57}/> */}
                    <span className={'secondTitle'}>NFT DISTRIBUTION / PROMOTION</span>
                    <div className={'nftDes'}>
                    Drink Up's operation team will handle the distribution and promotion of NFTs 
                    through various approaches and platforms, consisting of 
                    Dapp built-in mall,airdrop, community, Twitter, Instagram, to name just a few. 
                    </div>
                </div>
            </div>
            <div style={{textAlign:'center',marginTop:'92px'}}>
                <img src={titleRoad} width={365} height={88} alt={'road title'}/>
            </div>
        </div>
        <div ref={roadmapRef} style={{width:'100%',overflowX:'auto',paddingLeft:'calc(50% - 640px)'}}>
                <img src={roadmap} width='max-content' height='240px' alt={'roadmap'}/>
        </div>
        </>
    )
}

export default Overall