import React,{useState,useMemo} from 'react'

import nftDesign from '../../../assets/overall/icon:NFT DESIGN.png'
import nftEmbodiment from '../../../assets/overall/icon:NFT distribution:promotion.png'
import nftDistribution from '../../../assets/overall/icon:NFT distribution:promotion.png'
import nftDesignTitle from '../../../assets/about_us/nft Design.png'
import nftDistributionTitle from '../../../assets/about_us/promotion.png'
import nftEmbodimentTitle from '../../../assets/about_us/The embodiment of Gamefi model.png'

import NFTDetail from './NFTDetail'
import './style.css'


const NFTBlock = ()=>{
    const [cur,setCur] = useState('NFT DESIGN')

    const detailProps = useMemo(()=>{
        if(cur === 'NFT DESIGN'){
            return {
                iconSrc: nftDesign,
                titleSrc: nftDesignTitle,
                desc: `Work with DRINK UP's professional visual designers to 
                       design the NFTs that embody your corporate culture and brand.`
            }
        }else if(cur === 'NFT PATTERN'){
            return {
                iconSrc: nftEmbodiment,
                titleSrc: nftEmbodimentTitle,
                desc: `Work with DRINK UP's product managers and engineers to design 
                        the game values of NFT within DRINK UP, making your NFTs not merely 
                        good-looking but fun to play with as well.`
            }
        }else{
            return {
                iconSrc: nftDistribution,
                titleSrc: nftDistributionTitle,
                desc: `DRINK UP's operation team will handle the distribution and promotion of 
                        NFTs through various approaches and platforms, consisting of Dapp built-in mall, 
                        airdrop, community, Twitter, Instagram, to name just a few.`
            }
        }
    },[cur])

    return (
        <div style={{padding:'60px 70px 52px 86px'}}>
            <NFTDetail {...detailProps}/>
            <div style={{display:'flex',marginTop:'150px',padding:'0 224px',justifyContent:'space-between'}}>
                {['NFT DESIGN','NFT PATTERN','NFT SPREAD'].map(title=>(
                    <div key={title}>
                        <span 
                            onClick={()=>setCur(title)}
                            className={cur === title ? 'menu-item menu-item-focus' : 'menu-item'} 
                        >
                            {title}
                        </span>
                        {cur === title && <span className={'text-decoration-span'} style={{height:4}}/>}
                    </div>
                ))}
            </div>
           
        </div>
    )
}

export default NFTBlock